import axios from 'axios';

const URL_IXN = 'https://api.ixn.tech/v1/';
const HEADERS = {
  'APP-ID': 'bfd1a839-17bb-4ccc-9541-c65577c9adf9',
  'APP-TOKEN': '014dc0ec-6006-4dc8-90ca-db1dc50a85e3',
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

export const getinIXN = async (method, data = null) => {
  switch (method) {
    case 'get_carrier':
      return await axios.get(URL_IXN + "products?carrier_id=1594").then(res => {
        return res.data;
      }).catch(err => {
        return { status: 'Error' };
      });
    case 'get_quotes':
      return await axios.post(URL_IXN + "quotes", data, { headers: HEADERS }).then(res => {
        return res.data;
      }).catch(err => {
        return { status: 'Error' };
      });
    default: break;
  }
}
