import React, { createContext, useReducer } from "react";
import Reducer from './reducer'

const initialState = {
  inputs: {},
  error: null
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    // @ts-ignore
    <Context.Provider value={[state, dispatch]} displayName="ContextNames">{children}</Context.Provider>
  )
};

export const Context = createContext(initialState);
export default Store;