const Reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_INPUT':
      let objectCopy = state.inputs;
      let getinfo = Object.keys(action.payload).map(function (key) {
        window.analytics.track('step_data_dispashed', {
          field: key,
          value: action.payload[key],
          attempt_id: Date.now()
        })
        return objectCopy[key] = action.payload[key];
      });
      if (getinfo){
        return { ...state, inputs: objectCopy }
      }
      break;
    default: return state;
  }
};

export default Reducer;